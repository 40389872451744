import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Heading from '@/atoms/Heading';

import { media, scale } from '@/styles/utils';

const Title = styled(Heading)`
  position: relative;
  padding-left: ${scale(2.5)};
  display: flex;

  ${media.md`
    display: inline-block;
    padding-left: ${scale(3)};
  `}
`;

const Date = styled(Heading).attrs({ h: 3 })`
  position: absolute;
  left: 0;
  display: block;
`;

const Name = styled.span`
  display: inline-block;

  ${media.md`
    display: inline;
  `}
`;

export default ({ client, title, date, size = 2, ...props }) => (
  <Title h={size} {...props}>
    <Date
      as="span"
      $top={
        size === 2
          ? ['0.15em', '0.15em', '0.4em']
          : ['0.15em', '0.15em', '0.6em']
      }
    >
      {date}
    </Date>
    <Name>
      <span> {title} – </span>
      <Box as="span" $fontStyle="italic">
        {client}
      </Box>
    </Name>
  </Title>
);
