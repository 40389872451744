import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { getSrcsetSizes } from '@/utils/images';

const Image = styled(Img)`
  img:-moz-loading {
    visibility: hidden;
  }
`;

export default ({ asset, alt, columnWidth = 1, ...props }) => {
  const srcsetSizes = getSrcsetSizes(columnWidth);
  let imageProps = { alt };

  // add custom srcset sizes
  if (Array.isArray(asset)) {
    // art directed image (array)
    imageProps = {
      ...imageProps,
      fluid: asset.map(({ fluid, media }) => ({
        ...fluid,
        media,
        sizes: srcsetSizes,
      })),
    };
  } else if (asset) {
    // normal image
    imageProps = {
      ...imageProps,
      fluid: {
        ...asset.fluid,
        sizes: srcsetSizes,
      },
    };
  }

  return asset ? <Image {...imageProps} {...props} /> : null;
};

export const query = graphql`
  fragment imageWithAltData on SanityImageWithAlt {
    asset {
      fluid(maxWidth: 2400) {
        ...GatsbySanityImageFluid_withWebp
      }
    }
    alt
  }
`;
